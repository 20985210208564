var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-column py-1 px-4 justify-start",attrs:{"height":"100%"}},[_c('span'),_c('v-text-field',{staticClass:"flex-grow-0 flex-shrink-1",attrs:{"clearable":"","color":"primary","formatsend-inner-icon":"mdi-magnify","placeholder":"Search","rounded":"","single-line":"","solo":""},model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}}),_c('v-select',{staticClass:"flex-grow-0 flex-shrink-1",attrs:{"items":_vm.orderBy(_vm.categories, 'name'),"item-text":"name","item-value":"id","label":"Category","multiple":"","chips":"","deletable-chips":"","no-hint":"","no-title":"","clearable":"","color":"primary"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(
            _vm.filterCategory.includes(item.id)
              ? 'mdi-checkbox-marked'
              : 'mdi-checkbox-blank-outline'
          )}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":item.color},domProps:{"textContent":_vm._s('mdi-checkbox-blank-circle')}})],1)]}},{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"color":item.color,"close":""},on:{"click:close":function($event){_vm.categorySelect = _vm.categorySelect.filter(function (i) { return i !== item.id; })}}},[_c('span',[_vm._v(_vm._s(item.name))])])]}}]),model:{value:(_vm.categorySelect),callback:function ($$v) {_vm.categorySelect=$$v},expression:"categorySelect"}}),_c('p',{staticClass:"subtitle-2 mb-0 primary--text"},[_vm._v("DATE RANGE")]),_c('v-date-picker',{staticClass:"flex-grow-0 flex-shrink-1 mt-1",attrs:{"range":"","color":"primary","no-title":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('v-text-field',{attrs:{"value":_vm.dateRangeText,"no-label":"","formatsend-icon":"mdi-calendar-range","readonly":"","placeholder":"Select range above","clearable":""},on:{"click:clear":function($event){_vm.dateRange = []}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }